// vue2
import axios from 'axios'
import {
    Message,
    MessageBox
} from 'element-ui'

import router from '@/router'
import {baseUrl} from '@/utils/config'


// 配置新建一个 axios 实例
const request = axios.create({
    baseURL: baseUrl,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

// 添加请求拦截器
request.interceptors.request.use(
    (config) => {
        // 在发送请求之前做些什么 token
        if (localStorage.getItem('token')) {
            config.headers.Authorization = localStorage.getItem('token')
        }
        return config
    },
    (error) => {
        // 对请求错误做些什么
        return Promise.reject(error)
    }
)

// 添加响应拦截器
request.interceptors.response.use(
    (response) => {
        // 对响应数据做点什么
        const res = response.data

        if (res.code === 401) {
            localStorage.removeItem("token")
            localStorage.removeItem("userInfo")
            Message.warning('凭证过期，请重新登录')
            router.push('/login') // 去登录页面
            return Promise.reject(request.interceptors.response)
        } else {
            if (res.code !== 200) {
                Message.warning(res.message)
            }
            return res
        }
    },
    (error) => {
        // 对响应错误做点什么
        if (error.message.indexOf('timeout') !== -1) {
            Message.error('网络超时')
        } else if (error.message === 'Network Error') {
            Message.error('网络连接错误')
        } else {
            if (error.response.data) Message.error(error.response.statusText)
            else Message.error('接口路径找不到')
        }
        return Promise.reject(error)
    }
)

// 导出 axios 实例
export default request