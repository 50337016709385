import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin: false,
    admin: {}
  },
  mutations: {
    login(state, userInfo) {
      state.admin = userInfo
      state.isLogin = true
    },
    logout(state) {
      state.admin = {}
      state.isLogin = false
    },
  },
  actions: {
  },
  modules: {
  }
})
