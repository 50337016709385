import http from '@/utils/http' // 导入http中创建的axios实例

export default {
    // 搜索用户
    httpUserSearch(params) {
        return http.get('/user/search', {
            params
        })
    },
    // 编辑用户
    httpUserUpdate(data) {
        return http.put('/user/update', data)
    },
    // 删除用户
    httpUserDelete(id) {
        return http.delete('/user/delete', {
            params: {
                id
            }
        })
    },
    // 用户列表
    httpUserList(params) {
        return http.get('/user/list', {
            params
        })
    },
    // 用户信息
    httpUserInfo(id) {
        return http.get('/user/info', {
            params: {
                id
            }
        })
    },
}
