// element-ui 按需加载
import {
    Button,
    Input,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Dialog,
    Timeline,
    TimelineItem,
    Card,
    Row,
    Col,
    Menu,
    MenuItem,
    Submenu,
    Icon,
    Link,
    Empty,
    Badge,
    Avatar,
    Form,
    FormItem,
    Table,
    TableColumn,
    Backtop,
    Drawer,
    MenuItemGroup,
    Container,
    Header,
    Aside,
    Main,
    Tag,
    Breadcrumb,
    BreadcrumbItem,
    Select,
    Option,
    Radio,
    RadioGroup,
    Pagination,
    Upload,
    Checkbox,
    CheckboxGroup,
    InputNumber,
    Loading,
    Popconfirm,
} from 'element-ui'

export default (Vue) => {
    Vue.use(Button)
    Vue.use(Input)
    Vue.use(Dropdown)
    Vue.use(DropdownMenu)
    Vue.use(DropdownItem)
    Vue.use(Dialog)
    Vue.use(Timeline)
    Vue.use(TimelineItem)
    Vue.use(Card)
    Vue.use(Row)
    Vue.use(Col)
    Vue.use(Menu)
    Vue.use(MenuItem)
    Vue.use(Submenu)
    Vue.use(Icon)
    Vue.use(Link)
    Vue.use(Empty)
    Vue.use(Badge)
    Vue.use(Avatar)
    Vue.use(Form)
    Vue.use(FormItem)
    Vue.use(Table)
    Vue.use(TableColumn)
    Vue.use(Backtop)
    Vue.use(Drawer)
    Vue.use(MenuItemGroup)
    Vue.use(Container)
    Vue.use(Header);
    Vue.use(Aside);
    Vue.use(Main);
    Vue.use(Tag)
    Vue.use(Breadcrumb)
    Vue.use(BreadcrumbItem)
    Vue.use(Select)
    Vue.use(Option)
    Vue.use(Radio)
    Vue.use(RadioGroup)
    Vue.use(Pagination)
    Vue.use(Upload)
    Vue.use(Checkbox)
    Vue.use(CheckboxGroup)
    Vue.use(InputNumber)
    Vue.use(Loading)
    Vue.use(Popconfirm)
}