import http from '@/utils/http' // 导入http中创建的axios实例

export default {
    // 更新回复状态
    httpReplyUpdate(params) {
        return http.put('/msg/reply/update', params)
    },

    // 加载留言列表
    httpMessageList(params) {
        return http.get('/msg/message/list', {
            params
        })
    },
    // 加载评论列表
    httpCommentList(params) {
        return http.get('/msg/comment/list', {
            params
        })
    },
    // 加载留言列表
    httpReplyList(params) {
        return http.get('/msg/reply/list', {
            params
        })
    },

    // 删除评论（需要验证用户身份）
    httpCommentDelete(id) {
        return http.delete('/msg/comment/delete', {
            params: {
                id
            }
        })
    },
    // 删除回复（需要验证用户身份）
    httpReplyDelete(id) {
        return http.delete('/msg/reply/delete', {
            params: {
                id
            }
        })
    },
    // 删除留言（需要验证用户身份）
    httpMessageDelete(id) {
        return http.delete('/msg/message/delete', {
            params: {
                id
            }
        })
    },

    // 评论审核
    httpCommentCheck(params) {
        return http.put('/msg/comment/update', params)
    },
    // 回复审核
    httpReplyCheck(params) {
        return http.put('/msg/reply/update', params)
    },
    // 留言审核
    httpMessageCheck(params) {
        return http.put('/msg/message/update', params)
    },
    // 加载个人收到的回复
    httpUserReply(userId) {
        return http.get('/msg/reply/load', {
            params: {
                'replyUserId': userId
            }
        })
    }
}