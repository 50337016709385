import http from '@/utils/http' // 导入http中创建的axios实例

export default {
    // 标签列表
    httpTagList(params) {
        return http.get('/tag/list', {
            params
        })
    },
    // 搜索标签
    httpTagSearch(params) {
        return http.get('/tag/search', {
            params
        })
    },
    // 标签详情
    httpTagDetail(params) {
        return http.get('/tag/detail', {
            params
        })
    },
    // 标签新建
    httpTagCreate(data) {
        return http.post('/tag/create', data)
    },
    // 标签删除
    httpTagDetele(id) {
        return http.delete('/tag/delete', {
            params: {
                id
            }
        })
    },
    // 标签更新
    httpTagUpdate(data) {
        return http.put('/tag/update', data)
    },
}