class Storage {
    // 设置永久缓存
    setLocal(key, val) {
        window.localStorage.setItem(key, JSON.stringify(val))
    }

    // 获取永久缓存
    getLocal(key) {
        const json = window.localStorage.getItem(key)
        return JSON.parse(json)
    }

    // 移除永久缓存
    removeLocal(key) {
        window.localStorage.removeItem(key)
    }

    // 移除全部永久缓存
    clearLocal() {
        window.localStorage.clear()
    }

    // 2. sessionStorage
    // 设置临时缓存
    setSession(key, val) {
        window.sessionStorage.setItem(key, JSON.stringify(val))
    }

    // 获取临时缓存
    getSession(key) {
        const json = window.sessionStorage.getItem(key)
        return JSON.parse(json)
    }

    // 移除临时缓存
    removeSession(key) {
        window.sessionStorage.removeItem(key)
    }

    // 移除全部临时缓存
    clearSession() {
        window.sessionStorage.clear()
    }
}

export default new Storage()
