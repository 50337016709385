module.exports = {
    dev: {
        $cloud: "http://127.0.0.1:3001", // 文件云url
        $upload: "http://127.0.0.1:3001/upload", // 上传接口
        $update: "http://127.0.0.1:3001/file", // 上传接口
        $create: "http://127.0.0.1:3001/create", // 上传接口
        $api: "http://127.0.0.1:3000/api/v1/admin"
    },
    prd: {
        // $cloud: "https://file.zengweihao.cn", // 文件云url
        // $upload: "https://file.zengweihao.cn/upload", // 上传接口
        // $update: "https://file.zengweihao.cn/file", // 上传接口
        // $create: "https://file.zengweihao.cn/create", // 上传接口
        // $api: "https://server.zengweihao.cn/api/v1/admin"
        $cloud: "http://127.0.0.1:3001", // 文件云url
        $upload: "http://127.0.0.1:3001/upload", // 上传接口
        $update: "http://127.0.0.1:3001/file", // 上传接口
        $create: "http://127.0.0.1:3001/create", // 上传接口
        $api: "http://127.0.0.1:3000/api/v1/admin"
    }
}