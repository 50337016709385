import http from '@/utils/http' // 导入http中创建的axios实例

import config from '@/config/index'
const { $cloud, $upload, $update, $create } = config.dev

export default {
    // 统计ip
    httpIPCount(params) {
        return http.get('/system/ip/count', {
            params
        })
    },
    // 获取ip记录（访问记录）
    httpIPLog(params) {
        return http.get('/system/ip/log', {
            params
        })
    },
    // 获取ip
    httpGetIP() {
        return http.get('/system/ip')
    },
    // 文件云上传
    httpUPLOAD(url, data) {
        return http.post(url, data)
    },
    // 文件云生成文件
    httpCREATE(url, data) {
        return http.post(url, data)
    },
    // 文件云浏览
    httpGET(url) {
        return http.get(url)
    },
    // 文件云更新
    httpPUT(url, data) {
        return http.put(url, data)
    },



}