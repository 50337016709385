<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {
    // this.$bus.on('getUser', this.getUser)
    // this.$bus.on('saveUser', this.saveUser)
    // console.log(process.env.NODE_ENV)
    this.getUser()
  },
  methods: {
    // 获取用户信息
    async getUser() {
      const token = this.$storage.getSession('token')

      if (token) {
        const res = await this.$api.httpLoginToken(token)
        const { code, data } = res
        if (code == 200) {
          this.user = data.user
          this.$store.commit('login', this.user)

          /* this.user.userId = res.data.user.userId
          this.user.name = res.data.user.name
          this.user.email = res.data.user.email
          this.user.admin = res.data.user.admin */

          /* this.$store.commit('login', {
            userId: res.data.user.userId,
            name: res.data.user.name,
            email: res.data.user.email,
            admin: res.data.user.admin,
          }) */

        }
      }
    },
  }
}
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}

.table-page {
  .search {
    margin-bottom: 14px;
  }

  .content {
    .btn-wrap {
      margin-bottom: 16px;
      display: flex;
      justify-content: flex-end;
    }

    .pagination {
      display: flex;
      justify-content: center;
      margin: 24px 0;
    }
  }
}
</style>
