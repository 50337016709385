import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

/* Layout */
import Layout from '@/views/layout'

/* 解决重复点击相同导航报错 */
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [{
  path: '/',
  redirect: '/article/list',
},
{
  // 登录
  path: '/login',
  name: 'Login',
  component: () => import('../views/Login.vue')
},
{
  path: '/tag',
  meta: {
    title: '标签管理',
    icon: 'el-icon-discount'
  },
  component: Layout,
  children: [{
    path: '/tag/list',
    name: 'Tag',
    meta: {
      class: '标签管理',
      title: '标签列表',
      icon: 'el-icon-document'
    },
    component: () => import('../views/Tag.vue'),
  }
  ]
},
{
  path: '/article',
  name: 'Article',
  meta: {
    title: '文章管理',
    icon: 'el-icon-reading'
  },
  component: Layout,
  children: [{
    path: '/article/list',
    meta: {
      class: '文章管理',
      title: '文章列表',
      icon: 'el-icon-tickets'
    },
    component: () => import('../views/Article.vue'),
  }
  ]
},
{
  path: '/msg',
  name: 'Meg',
  meta: {
    title: '消息管理',
    icon: 'el-icon-message'
  },
  component: Layout,
  children: [
    {
      path: '/msg/comment',
      meta: {
        class: '消息管理',
        title: '评论列表',
        icon: 'el-icon-chat-line-square'
      },
      component: () => import('../views/Comment.vue'),
    }
  ]
}, {
  path: '/user',
  name: 'User',
  meta: {
    title: '成员管理',
    icon: 'el-icon-user'
  },
  component: Layout,
  children: [{
    path: '/user/client',
    meta: {
      class: '成员管理',
      title: '用户管理',
      icon: 'el-icon-coin'
    },
    component: () => import('../views/User.vue'),
  }]
},
{
  path: '*',
  name: '404',
  component: () => import('../views/common/404.vue')
}
]

const router = new VueRouter({
  routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
  let isAuthenticated = localStorage.getItem('token')
  if (to.name !== 'Login' && !isAuthenticated) next({
    name: 'Login'
  })
  else next()
})

export default router