import http from '@/utils/http' // 导入http中创建的axios实例

export default {
    // 文章列表
    httpArticleSearch(params) {
        return http.get('/article/search', {
            params
        })
    },
    // 文章列表
    httpArticleList(params) {
        return http.get('/article/list', {
            params
        })
    },
    // 草稿箱
    httpArticleDraft(params) {
        return http.get('/article/draft', {
            params
        })
    },
    // 文章详情
    httpArticleDetail(id) {
        return http.get('/article/detail', {
            params: {
                id
            }
        })
    },
    // 文章新建
    httpArticleCreate(data) {
        return http.post('/article/create', data)
    },
    // 文章更新
    httpArticleUpdate(data) {
        return http.put('/article/update', data)
    },
    // 文章删除
    httpArticleDetele(id) {
        return http.delete('/article/delete', {
            params: {
                id
            }
        })
    },
    // 文章条形图数据
    httpArticleBar() {
        return http.get('/article/bar')
    },
}