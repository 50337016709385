import http from '@/utils/http' // 导入http中创建的axios实例

export default {
    // 搜索管理员
    httpAdminSearch(params) {
        return http.get('/admin/search', {
            params
        })
    },
    // 令牌登录
    httpLoginToken(token) {
        return http.post('/admin/login/token', { token })
    },
    // 登录
    httpLogin(data) {
        return http.post('/admin/login', data)
    },
    // 管理员列表
    httpAdminList(params) {
        return http.get('/admin/list', {
            params
        })
    },
    // 管理员信息
    httpAdminInfo(id) {
        return http.get('/admin/info', {
            params: {
                id
            }
        })
    },
    // 编辑管理员信息
    httpAdminUpdate(data) {
        return http.put('/admin/update', data)
    },
}
