import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


// 按需加载 element.ui
import ElementUI from './plugins/element'
import 'element-ui/lib/theme-chalk/index.css'
// 基于断点的隐藏类
import 'element-ui/lib/theme-chalk/display.css';
ElementUI(Vue)
import {
  Message,
  MessageBox
} from 'element-ui'
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$alert = MessageBox.alert

// 备用 处理 multipart/form-data 类型
import axios from 'axios'
Vue.prototype.$axios = axios

// md 
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
Vue.use(mavonEditor)

import api from '@/api/index'
Vue.prototype.$api = api

import request from '@/utils/request'
Vue.prototype.$request = request

import storage from '@/utils/storage'
Vue.prototype.$storage = storage


import { prd } from '@/config'

// 文件云url
Vue.prototype.$cloud = prd.$cloud
Vue.prototype.$upload = prd.$upload
Vue.prototype.$update = prd.$update

Vue.prototype.bus = new Vue()

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')