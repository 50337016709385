<template>
  <el-aside class="nav">
    <el-menu :default-active="activeIndex" :collapse="isCollapse" background-color="#304156" text-color="#fff"
      active-text-color="#3b8fe6">
      <h1 class="logo">后台管理</h1>
      <!-- 父节点 -->
      <template v-for="item in menuList">
        <!-- 排除404 -->
        <template v-if="item.meta">
          <!-- 有子节点 -->
          <template v-if="item.children[0].meta && item.children[0].meta.title">
            <el-submenu :index="item.path" :key="item.path">
              <template slot="title">
                <i :class="item.meta.icon"></i>
                <span>{{item.meta.title}}</span>
              </template>
              <el-menu-item-group>
                <template v-for="son in item.children">
                  <el-menu-item v-if="son.meta && son.meta.icon" :key="son.path" :index="son.path"
                    @click="goto(son.path)"><i :class="son.meta.icon"></i><span>{{son.meta.title}}</span></el-menu-item>
                </template>
              </el-menu-item-group>
            </el-submenu>
          </template>
          <!-- 没有子项 -->
          <template v-else>
            <el-menu-item :index="item.path" :key="item.path" @click="goto(item.path)">
              <i :class="item.meta.icon"></i>
              <span slot="title">{{item.meta.title}}</span>
            </el-menu-item>
          </template>

        </template>
      </template>
    </el-menu>
  </el-aside>
</template>

<script>
export default {
  name: 'Nav',
  data() {
    return {
      activeIndex: '/',
      isCollapse: false,
      menuList: [],
    }
  },
  watch: {
    $route() {
      this.activeIndex = this.$route.path
      // console.log(this.$route.path)
    },
  },
  created() {
    this.activeIndex = this.$route.path
    this.menuList = this.$router.options.routes
    this.bus.$on('onputNav', () => {
      this.isCollapse = !this.isCollapse
    })
  },
  methods: {
    goto(path) {
      this.$router.push(path)
    },
  },
}
</script>

<style scoped lang="scss">
.nav {
  background-color: #304156;
  height: 100vh;
  width: auto !important;
  position: sticky;
  top: 0;
  overflow: hidden;

  .el-menu {
    border: none;
    text-align: left;
    ::v-deep .el-menu-item {
      min-width: auto !important;
    }
    ::v-deep .el-submenu__title {
      padding-right: 80px;
    }
    .logo {
      color: rgb(255, 255, 255);
      background-color: rgb(48, 65, 86);
      height: 56px;
      line-height: 56px;
      padding: 0 20px;
      cursor: pointer;
      text-align: center;
    }
  }
}
</style>
