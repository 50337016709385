<template>
  <el-container class="layout">
    <Nav />
    <el-container style="flex-direction: column;">
      <Header />
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Header from './Header.vue'
import Nav from './Nav.vue'
export default {
  components: {
    Nav,
    Header,
  },
  data() {
    return {
      themeDark: false,
    }
  },
  methods: {},
}
</script>

<style lang="scss">
.layout {
  background-color: #f3f3f3;
}
</style>
