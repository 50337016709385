import article from "./article"
import msg from "./msg"
import tag from "./tag"
import user from "./user"
import admin from "./admin"
import other from "./other"

const api = {
    ...article,
    ...msg,
    ...tag,
    ...user,
    ...admin,
    ...other
}

export default api