<template>
  <el-header class="header">
    <i :class="isCollapse ? 'el-icon-s-unfold put_nav' : 'el-icon-s-fold put_nav'" @click="useputNav"></i>

    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: lastPath }">返回上一页面</el-breadcrumb-item>
      <el-breadcrumb-item>{{ $route.meta.class }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ $route.meta.title }}</el-breadcrumb-item>
    </el-breadcrumb>

    <el-dropdown class="info" @command="handleCommand">
      <span class="el-dropdown-link">
        <i class="el-icon-setting" style="margin-right: 15px;"> {{ admin.name }}</i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="logout">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <!-- 全屏 -->
    <i class="el-icon-full-screen" @click="useScreenfull"></i>
  </el-header>
</template>


<script>
import screenfull from 'screenfull/dist/screenfull'
export default {
  data() {
    return {
      isCollapse: false,
      breadcrumb: {},
      // admin: {
      //   name: '',
      //   userId: '',
      // },
      lastPath:'/'
    }
  },
  watch: {
    $route(n,o) {
      // console.log(this.$router.options.routes)
      this.lastPath = o.fullPath
    },
  },
  computed: {
    admin() {
      return this.$store.state.admin
    }
  },
  created() {
    // console.log(this.$router.options.routes )
    // this.getLoginInfo()
  },
  methods: {
    // 全屏
    useScreenfull() {
      if (screenfull.isEnabled) {
        // screenfull.request()
        screenfull.toggle()
      } else {
        this.$message.error("抱歉，当前浏览器不支持全屏")
      }
    },
    // 下拉菜单选择
    handleCommand(command) {
      switch (command) {
        case 'logout':
          this.logout()
          break
      }
    },
    // 退出登录
    logout() {
      this.$confirm('是否退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$storage.removeSession('token')
          this.$store.commit('logout')
          this.$message.success('成功退出')
          this.$router.push('/login')
        })
        .catch(() => { })
    },
    // 缩小导航栏
    useputNav() {
      this.isCollapse = !this.isCollapse
      this.bus.$emit('onputNav')
    },
    // 获取登录信息
    // getLoginInfo() {
    //   const auth = this.$storage.getSession('admin')
    //   if (auth) {
    //     // console.log(auth)
    //     this.admin.name = auth.name
    //     this.admin.userId = auth.userId
    //   }
    // },
  },
}
</script>

<style scoped lang="scss">
.header {
  z-index: 999;
  background-color: #fff;
  position: sticky;
  top: 0;
  width: 100%;
  height: 50px;
  line-height: 50px;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  overflow: hidden;

  // 收导航栏
  .put_nav {
    font-size: 26px;
    cursor: pointer;
    display: block;
    float: left;
    line-height: 50px;
    margin-right: 20px;
  }

  // 全屏
  .el-icon-full-screen {
    display: block;
    float: right;
    line-height: 50px;
    cursor: pointer;
    font-size: 26px;
    margin-right: 20px;
  }

  .el-breadcrumb {
    float: left;
    line-height: 50px;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }

  .info {
    float: right;
  }
}
</style>
